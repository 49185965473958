<template>
  <!-- <Slider /> -->
  <SlideShow/>
  <ContentField>
    <p>
      Здесь всего за 15 минут приготовим овальную римскую пиццу и напитки и
      подадим их на теплой и уютной террасе с потрясающим видом. Обедать на
      свежем воздухе на берегу тихого лесного озера, согреваясь чашкой
      ароматного глинтвейна — вот рецепт идеального выходного!
    </p>

    <p>
      В меню вы найдете 5 видов пиццы: от классической Маргариты или «Четыре
      сыра» до ярких сезонных сочетаний — например, с лисичками. Кроме того, вы
      можете взять с собой ароматный домашний хлеб и свежую выпечку — круассаны
      и мини-пышки.
    </p>
    <h3>Сейчас пиццерия «Лавка» работает так:</h3>
    <!-- <p>
      <strong>ПН — ПТ с 12 до 20:00</strong>
    </p>

    <p>
      (заказы принимаем онлайн в приложении, забрать заказ можно уже через 15
      минут после оплаты в окне выдачи заказов);
    </p> -->
    <br>
    <p><strong>СБ — ВС и праздничные дни с 12 до 20:00</strong></p>
    <p>
      (заказы принимаем онлайн в приложении или лично — в окне приема заказов
      пиццерии).
    </p>
  </ContentField>
  <YaMap />
</template>

<script>
import ContentField from '@/components/ContentField.vue'
// import Slider from '@/components/Slider.vue'
import YaMap from '../components/YaMap.vue'
import SlideShow from '../components/SlideShow.vue'
export default {
  name: 'AboutView',
  components: { ContentField, YaMap, SlideShow }
}
</script>
